/* color you need to use: 
	#0B0C10
	#1F2833
	#C5C6C7
	#66FCF1
	#45A29E

*/
@viewport {
	width: device-width;
	zoom: 1;
}
.App {
	text-align: center;
	max-width: 100%;
	height: auto;
}
* {
	list-style: none;
	font-family: 'Montserrat', sans-serif;
}
body {
	background-color: #0b0c10;
	scroll-behavior: smooth;
}
#navbar {
	background-color: #1f2833;
	display: flex;
	justify-content: left;
	align-items: left;
	float: left;
	overflow: hidden;
	position: fixed;
	z-index: 1;
	top: 0;
	width: 100%;
	min-width: 75px;
	min-height: 75px;
	height: auto;
	margin-bottom: 20px;
	padding-top: 20px;
	box-shadow: 0 5px 5px -2px rgba(0, 0, 0, .2);
}
#navbar li {
	display: inline;
	padding-right: 15px;
	font-size: 35px;
	color: #66fcf1;
}

#home-div {
	max-width: 100%;
	height: auto;
	padding-top: 200px;
	color: #c5c6c7;
	padding-left: 15%;
	padding-right: 15%;
	text-align: justify;
}
#about-div {
	padding-top: 200px;
	padding-left: 10%;
	padding-right: 10%;
	padding-bottom: 10%;
	max-width: 100%;
	height: auto;
	min-width: 75px;
	color: #66fcf1;
}
#about h1 {
	color: #45a29e;
	font-weight: 400;
	text-shadow: 2px 2px #1f2833;
}
#about h5 {
	color: #45a29e;
	font-weight: 400;
}
#about {
	border: 7px solid #66fcf1;
	background-color: #1f2833;
	font-weight: 700;
	font-size: 25px;
	border-radius: 10px;
}
#projects-div {
	padding-top: 200px;
	padding-left: 20%;
	padding-right: 20%;
	padding-bottom: 200px;
	color: #1f2833;
	max-width: 100%;
	width: auto;
	height: auto;
	min-width: 75px;
}
#projects-front {
	border: 7px solid #66fcf1;
	background-color: #1f2833;
	border-radius: 10px;
	padding-bottom: 15px;
}
#projects-front h4 {
	color: #45a29e;
}

#projects-back {
	border: 7px solid #66fcf1;
	background-color: #1f2833;
	border-radius: 10px;
	width: inherit;
	height: 473px;
}
#projects-front button,
#projects-back button {
	color: #45a29e;
	font-size: 25px;
	background-color: #0b0c10;
	/* padding-bottom: 10px; */
}
#projects-front h1 {
	color: #45a29e;
	font-weight: 400;
	text-shadow: 1px 1px #1f2833;
}
#projects-div:last-child {
	padding-bottom: 200px;
}
#projects-front img {
	width: 100%;
	min-width: 50px;
	height: auto;
	border: 2px solid #1f2833;
	border-radius: 5px;
}
#projects-front:hover {
	transition: all .2s ease-in-out;
	transform: scale(1.1);
}
#projects-back:hover,
#projects-back i:hover {
	transition: all .2s ease-in-out;
	transform: scale(1.1);
}
#projects-back i {
	font-size: 150px;
	color: #45a29e;
	padding-top: 150px;
	padding-right: 20px;
}

#resume-div {
	padding-top: 200px;
	padding-left: 20%;
	padding-right: 20%;
	color: #66fcf1;
}
#resume h1 {
	color: #45a29e;
	font-weight: 400;
	text-shadow: 1px 1px #1f2833;
}
#resume p {
	color: #45a29e;
	font-weight: 400;
	text-shadow: 1px 1px #1f2833;
}
#resume img {
	max-width: 100%;
	height: auto;
	min-width: 75px;
	padding-bottom: 10%;
}
#contact-div {
	padding-top: 200px;
	padding-left: 10%;
	padding-right: 10%;
	max-width: 100%;
	min-width: 75px;
	height: auto;
	color: #66fcf1;
}
#contact h1 {
	color: #45a29e;
	font-weight: 400;
	text-shadow: 2px 2px #1f2833;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 55px;
}
#contact i {
	color: #45a29e;
	font-size: 35px;
	padding-right: 10px;
}
#contact li {
	color: #45a29e;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 400;
}
#contact a:any-link {
	color: #45a29e;
}
#contact {
	border: 7px solid #66fcf1;
	background-color: #1f2833;
	border-radius: 10px;
	font-size: 25px;
}

#headshot-div img {
	width: 400px;
	max-width: 100%;
	min-width: 50px;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	margin-left: 20px;
	padding: 1px;
	border: 7px solid #66fcf1;
	float: left;
}
.fade-in-left {
	-webkit-animation: fade-in-left 1.0s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-left 1.0s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-8-26 19:33:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
	0% {
		-webkit-transform: translateX(-50px);
		transform: translateX(-50px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes fade-in-left {
	0% {
		-webkit-transform: translateX(-50px);
		transform: translateX(-50px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

#name-div {
	position: relative;
	z-index: 2;
	float: right;
	padding-top: 50px;
}
.slide-in-right {
	-webkit-animation: slide-in-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-8-26 19:35:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

#name {
	color: #45a29e;
	font-weight: 700;
	padding-left: 60px;
}
#title {
	font-weight: 500;
	padding-left: 60px;
}

#languages li {
	display: inline;
	padding-left: 25px;
}
#languages li i {
	font-size: 100px;
	color: #45a29e;
}
.slide-in-bottom {
	-webkit-animation: slide-in-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-8-26 20:6:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-9-2 13:53:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
.fade-in-bck {
	-webkit-animation: fade-in-bck 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-bck 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-9-2 13:57:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bck
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bck {
	0% {
		-webkit-transform: translateZ(80px);
		transform: translateZ(80px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}
}
@keyframes fade-in-bck {
	0% {
		-webkit-transform: translateZ(80px);
		transform: translateZ(80px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}
}

#footer {
	background-color: #1f2833;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 60px;
}
#footer div {
	color: #66fcf1;
	padding-top: 20px;
}
